import { campaignStatus, mailingItemStatus, resendType, winningCriteria } from '@/shared/models/mailing'
import { taStatus, orderType } from '@/shared/models/noww'

export const enumsMixin = {

  computed: {
    $_taStatus_Draft () {
      return taStatus.find(x => x.text === 'Draft').value
    },
    $_taStatus_Completed () {
      return taStatus.find(x => x.text === 'Completed').value
    },

    $_campaignStatus_Draft () {
      return campaignStatus.find(x => x.text === 'Draft').value
    },
    $_campaignStatus_Actvie () {
      return campaignStatus.find(x => x.text === 'Active').value
    },
    $_campaignStatus_Completed () {
      return campaignStatus.find(x => x.text === 'Completed').value
    },
    $_campaignStatus_Canceled () {
      return campaignStatus.find(x => x.text === 'Canceled').value
    },

    $_mailingItemStatus_Draft () {
      return mailingItemStatus.find(x => x.text === 'Draft').value
    },
    $_mailingItemStatus_Tested () {
      return mailingItemStatus.find(x => x.text === 'Tested').value
    },
    $_mailingItemStatus_Scheduled () {
      return mailingItemStatus.find(x => x.text === 'Scheduled').value
    },
    $_mailingItemStatus_Sent () {
      return mailingItemStatus.find(x => x.text === 'Sent').value
    },
    $_mailingItemStatus_Canceled () {
      return mailingItemStatus.find(x => x.text === 'Canceled').value
    },
    $_mailingItemStatus_Sending () {
      return mailingItemStatus.find(x => x.text === 'Sending').value
    },

    $_resendType_FullFile () {
      return resendType.find(x => x.text === 'Full File').value
    },
    $_resendType_NonOpens () {
      return resendType.find(x => x.text === 'Non-Opens').value
    },
    $_resendType_OpensNonClicks () {
      return resendType.find(x => x.text === 'Opens, Non-Clicks').value
    },
    $_resendType_Opens () {
      return resendType.find(x => x.text === 'Opens').value
    },
    $_resendType_NonClicks () {
      return resendType.find(x => x.text === 'Non-Clicks').value
    },

    $_winningCriteria_UniqueOpens () {
      return winningCriteria.find(x => x.text === 'Unique Opens').value
    },
    $_winningCriteria_GrossOpens () {
      return winningCriteria.find(x => x.text === 'Gross Opens').value
    },
    $_winningCriteria_UniqueClicks () {
      return winningCriteria.find(x => x.text === 'Unique Clicks').value
    },
    $_winningCriteria_GrossClicks () {
      return winningCriteria.find(x => x.text === 'Gross Clicks').value
    },

    $_orderType_NotDefined () {
      return orderType.find(x => x.text === 'Not Defined').value
    },
    $_orderType_DirectMailTelemarketing () {
      return orderType.find(x => x.text === 'Direct Mail/Telemarketing').value
    },
    $_orderType_Telemarketing () {
      return orderType.find(x => x.text === 'Telemarketing').value
    },
    $_orderType_EmailRelease () {
      return orderType.find(x => x.text === 'Email Release').value
    },
    $_orderType_EmailBroadcast () {
      return orderType.find(x => x.text === 'Email Broadcast').value
    },
    $_orderType_DirectMail () {
      return orderType.find(x => x.text === 'Direct Mail').value
    }
  },

  methods: {
    $_resendType_display (value) {
      return resendType.find(x => x.value === value).text
    },
    $_winningCriteria_display (value) {
      return winningCriteria.find(x => x.value === value).text
    }
  }

}
