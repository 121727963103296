export const windowMixin = {

  data: function () {
    return {
      windowMixin_windowHeight: 0,
      windowMixin_containerMinHeight: 0,
      windowMixin_headerSelector: document.getElementById('core-header'),
      windowMixin_footerSelector: document.getElementById('core-footer'),
      windowMixin_contentSelector: document.getElementById('router-container')
    }
  },

  watch: {
    windowMixin_windowHeight: {
      handler (newValue, oldValue) {
        // console.log('header ' + this.$_windowMixin_getHeaderHeight())
        // console.log('footer ' + this.$_windowMixin_getFooterHeight())
        this.windowMixin_containerMinHeight =
          (newValue || oldValue) -
          // this.$_windowMixin_getHeaderHeight() -
          this.$_windowMixin_getFooterHeight()
        // console.log('container min height ' + this.windowMixin_containerMinHeight)
      }
    }
  },

  mounted () {
    this.$nextTick(function () {
      window.addEventListener('resize', this.$_windowMixin_getWindowHeight)
      // Init
      this.$_windowMixin_getWindowHeight()
    })
  },

  beforeDestroy () {
    window.removeEventListener('resize', this.$_windowMixin_getWindowHeight)
  },

  computed: {
    $_windowMixin_contentHeight () {
      return this.windowMixin_windowHeight - this.$_windowMixin_getHeaderHeight() - this.$_windowMixin_getFooterHeight()
      // this.windowMixin_contentSelector.style.height.toString().replace('px', '')
    }
  },

  methods: {
    $_windowMixin_getWindowHeight (event) {
      this.windowMixin_windowHeight = document.documentElement.clientHeight
    },
    $_windowMixin_getHeaderHeight () {
      return this.windowMixin_headerSelector
        ? this.windowMixin_headerSelector.style.height.toString().replace('px', '')
        : 85
    },
    $_windowMixin_getFooterHeight () {
      return 76
      // return this.windowMixin_footerSelector
      //   ? this.windowMixin_footerSelector.style.height.toString().replace('px', '')
      //   : 76
    },
    $_windowMixin_getContentWidth () {
      this.$_windowMixin_refreshContentSelector()
      return this.windowMixin_contentSelector
        ? this.windowMixin_contentSelector.offsetWidth
        : 1200
    },
    $_windowMixin_refreshContentSelector () {
      this.windowMixin_contentSelector = document.getElementById('router-container')
      return this.windowMixin_contentSelector
    },
    $_windowMixin_addContentClass (className) {
      if (this.$_windowMixin_refreshContentSelector()) {
        this.windowMixin_contentSelector.classList.add(className)
      }
    },
    $_windowMixin_removeContentClass (className) {
      if (this.$_windowMixin_refreshContentSelector()) {
        this.windowMixin_contentSelector.classList.remove(className)
      }
    }
  }

}
