export const wscMixin = {
  created () {
    // Account Id: 0925540336
    window.WEBSPELLCHECKER_CONFIG = {
      autoSearch: true,
      autoDestroy: true,
      removeBranding: true, // needs a custom license
      // settingsSections: ['options', 'languages'],
      serviceId: '1:O4YSr4-NHmnj-XCcET3-W4VO92-PyE6m1-eJYHs2-DioMb2-dzxBI1-UqXBQ2-cP5Pi2-YNvqp3-Kf2'
    }
  },
  mounted () {
    // load the script from SaaS
    const plugin = document.createElement('script')
    plugin.setAttribute(
      'src',
      'https://svc.webspellchecker.net/spellcheck31/wscbundle/wscbundle.js'
    )
    plugin.async = true
    document.head.appendChild(plugin)
  }
}
