import { TableCSVExporter } from '@/shared/models/mailing'

export const downloadFileMixin = {
  methods: {
    $_downloadFileMixin_downloadResponse (resp) {
      var contentDisposition = resp.headers['content-disposition']
      let filename = 'noww_download'
      if (contentDisposition) {
        var match = contentDisposition.match(
          /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
        )
        if (match.length >= 2) {
          filename = match[1]
        }
      }
      const url = window.URL.createObjectURL(
        new Blob([resp.data], { type: resp.headers['content-type'] })
      )
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', filename) // or any other extension
      document.body.appendChild(link)
      link.click()
      link.remove()
      window.URL.revokeObjectURL(url)
    },
    async $_downloadFileMixin_downloadReportTable (tableId, fileName) {
      const dataTable = document.getElementById(tableId)
      const exporter = new TableCSVExporter([dataTable], [document.getElementById('headerTable1'), document.getElementById('headerTable2')])
      const csvOutput = exporter.convertToCSV()
      const csvBlob = new Blob([csvOutput], { type: 'application/text' })
      const blobUrl = URL.createObjectURL(csvBlob)
      const anchorElement = document.createElement('a')

      anchorElement.href = blobUrl
      anchorElement.download = `${fileName}.csv`
      anchorElement.click()

      setTimeout(() => {
        URL.revokeObjectURL(blobUrl)
      }, 500)
    },
    async $_downloadFileMixin_downloadReportChart (chart, appendDataWith, fileName, headers = false) {
      var csvStr = this.$_downloadFileMixin_getChartReportAsText(chart, appendDataWith, chart.datasets.length + 1, headers)

      const csvBlob = new Blob([csvStr], { type: 'application/text' })
      const blobUrl = URL.createObjectURL(csvBlob)
      const anchorElement = document.createElement('a')

      anchorElement.href = blobUrl
      anchorElement.download = `${fileName}.csv`
      anchorElement.click()

      setTimeout(() => {
        URL.revokeObjectURL(blobUrl)
      }, 500)
    },
    async $_downloadFileMixin_downloadDataTableReport (tableId, fileName) {
      const dataTable = document.getElementById(tableId)
      const exporter = new TableCSVExporter([dataTable])
      const csvOutput = exporter.convertToCSV()
      const csvBlob = new Blob([csvOutput], { type: 'application/text' })
      const blobUrl = URL.createObjectURL(csvBlob)
      const anchorElement = document.createElement('a')

      anchorElement.href = blobUrl
      anchorElement.download = `${fileName}.csv`
      anchorElement.click()

      setTimeout(() => {
        URL.revokeObjectURL(blobUrl)
      }, 500)
    },
    $_downloadFileMixin_getChartReportAsText (chart, appendDataWith, totalColumnCount, headers = false) {
      var csvStr = ''
      if (headers) {
        var tempStr = ''
        chart.datasets.forEach((val, index) => {
          tempStr += `,${val.label.trim()}`
        })
        for (let i = chart.datasets.length + 1; i < totalColumnCount; i++) {
          tempStr += ','
        }
        tempStr += '\n'
        csvStr += tempStr
      }
      chart.labels.forEach((val, index) => {
        var tempStr = val.trim()
        chart.datasets.forEach((dataset, index2) => {
          tempStr += `,${dataset.data[index]}${appendDataWith}`
        })
        for (let i = chart.datasets.length + 1; i < totalColumnCount; i++) {
          tempStr += ','
        }
        tempStr += '\n'
        csvStr += tempStr
      })
      return csvStr
    },
    async $_downloadFileMixin_downloadGeoData (geoData, fileName) {
      var csvStr = 'Geo Id,County,State,County Name,State Name,County Census Area,State Census Area,Received,Opens,Clicks,Click to Open Rate (%),Open Rate (%),Click Rate (%),Average Viewing Seconds (s),Total Viewing Seconds (s)\n'
      geoData.forEach((geo) => {
        csvStr += `${geo.geoId},${geo.county},${geo.state},${geo.countyName},${geo.stateName},${geo.countyCensusArea},${geo.stateCensusArea},${geo.received},${geo.uniqueOpens},${geo.uniqueClicks},${geo.clickToOpen},${geo.uniqueOpenRate},${geo.uniqueClickRate},${geo.averageViewingSeconds},${geo.totalViewingSeconds}\n`
      })

      const csvBlob = new Blob([csvStr], { type: 'application/text' })
      const blobUrl = URL.createObjectURL(csvBlob)
      const anchorElement = document.createElement('a')

      anchorElement.href = blobUrl
      anchorElement.download = `${fileName}.csv`
      anchorElement.click()

      setTimeout(() => {
        URL.revokeObjectURL(blobUrl)
      }, 500)
    }
  }
}
